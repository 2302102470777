import { VFC } from "react";

import { Trans } from "@lingui/macro";

import { Button } from "@chakra-ui/react";

import { FiAlertTriangle } from "@react-icons/all-files/fi/FiAlertTriangle";
import { ImFilesEmpty } from "@react-icons/all-files/im/ImFilesEmpty";
import { MdPageview } from "@react-icons/all-files/md/MdPageview";

import Fetching from "components/fetching";
import NotIdealState from "components/not-ideal-state";
import ProjectCreateButton from "components/project-create-button";

import { useClearQueryParams } from "shared/lib/hooks/use-update-query-params";

type ErrorViewProps = {
  httpStatus?: number;
};

export const ErrorView: VFC<ErrorViewProps> = () => {
  // TODO handle 401 on other layer
  // if (httpStatus === HttpStatus.UNAUTHORIZED) return <RedirectToLogin />;

  return (
    <NotIdealState
      icon={FiAlertTriangle}
      iconProps={{ color: "red.600" }}
      title={<Trans>Error</Trans>}
      description={<Trans>Could not fetch the project</Trans>}
    />
  );
};

export const EmptyProjectsView = () => (
  <NotIdealState
    icon={ImFilesEmpty}
    iconProps={{ color: "gray.500" }}
    title={<Trans>No projects</Trans>}
    description={<ProjectCreateButton />}
  />
);

export const FetchingLayoutView = () => (
  <Fetching message={<Trans>Fetching the projects</Trans>} />
);

export const PageWithoutData = () => {
  const clearQuery = useClearQueryParams();

  return (
    <NotIdealState
      icon={MdPageview}
      iconProps={{ color: "gray.500" }}
      title={<Trans>Projects not found</Trans>}
      description={
        <Button colorScheme="telegram" variant="outline" onClick={clearQuery}>
          <Trans>Go Back</Trans>
        </Button>
      }
    />
  );
};
