import { t } from "@lingui/macro";

import { iconsMapByModelId } from "components/processing-card/out-collapse";

import { DisplayModelType, Model, ModelId, Schema } from "types";

export type ModelGroup = {
  name: string;
  options: string[];
};

export type FlatItem = {
  name: string;
  icon: React.FC;
  models: Model[];
};

// Each group should has "by" prefix, in others each model presented as separated group
export type ModelsGrouped = {
  byBuildings: Model[];
  others: Model[];
};

export type ModelsPreset = {
  name: string;
  models: ModelId[];
};

const UrbanGroup: ModelsPreset = {
  name: t`model_preset:urban-mapping`,
  models: [
    ModelId.Buildings,
    ModelId.DenseBuildings,
    ModelId.UavBuildings,
    ModelId.Constructions,
  ],
};

const LandGroup: ModelsPreset = {
  name: t`model_preset:land-use`,
  models: [ModelId.Fields, ModelId.Forest],
};

const modelPresets: ModelsPreset[] = [UrbanGroup, LandGroup];

export const groupSchemaByModelPresets = (schema: Schema): ModelsPreset[] => {
  return modelPresets
    .map((group) => {
      const models = group.models.filter((modelId) =>
        schema.models.some(({ id }) => id === modelId)
      );

      return {
        name: group.name,
        models,
      };
    })
    .filter(({ models }) => models.length > 0);
};

export const toFlatModelsGroups = (groups: ModelsGrouped) => {
  return [
    {
      name: "byBuildings",
      icon: iconsMapByModelId[ModelId.Buildings],
      models: groups.byBuildings,
    },
    ...groups.others.map((model) => ({
      name: model.id,
      // icon: iconsMapByModelId[model.id],
      models: [model],
    })),
  ];
};

export const pickModelId = (model: Model): string => model.id;

export const countModels = (groups: ModelsGrouped) => {
  return Object.values(groups).reduce((acc, group) => group.length + acc, 0);
};

export const getAllModels = (groups: ModelsGrouped) => {
  return Object.values(groups).reduce<string[]>((acc, group) => {
    return acc.concat(group.map(pickModelId));
  }, []);
};

export const getMaxOptions = (models: Model[]) => {
  return Math.max(...models.map((model) => model.blocks.length));
};

//

export const encodeModels = (schema: Schema, models: DisplayModelType) => {
  return models.length === schema.models.length ? "all" : models;
};
