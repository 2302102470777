import { Box, BoxProps, Flex } from "@chakra-ui/layout";
import { Text, Tooltip } from "@chakra-ui/react";

import { IconType } from "@react-icons/all-files";

type Props = BoxProps & {
  count: number;
  color: string;
  icon: IconType;
  hideZero?: boolean;
  tooltipMessage?: string;
  tooltipAreaLabel?: string;
};

export const CountTag = ({
  count,
  icon,
  color,
  tooltipMessage = undefined,
  tooltipAreaLabel = "",
  hideZero = false,
  ...boxProps
}: Props) => {
  if (count > 0 || !hideZero)
    return (
      <Tooltip
        hasArrow
        rounded="md"
        aria-label={tooltipAreaLabel}
        label={tooltipMessage}
        placement="top"
      >
        <Box {...boxProps}>
          <Flex py={1} px={1} color={color} alignItems="center">
            <Box as={icon} w={4} h={4} mr={1} />
            <Text>{count}</Text>
          </Flex>
        </Box>
      </Tooltip>
    );

  return null;
};
