import { memo } from "react";

import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, Stack, Tooltip } from "@chakra-ui/react";

import { IoIosTimer } from "@react-icons/all-files/io/IoIosTimer";

import { matchLocale } from "shared/lib/i18n";

import TimeAgo from "timeago-react";

type Props = {
  created: string;
  updated: string;
};

export const DateMark = memo(({ created, updated }: Props) => {
  const { i18n } = useLingui();
  const color = useColorModeValue("gray.500", "gray.400");

  return (
    <Tooltip
      hasArrow
      rounded="md"
      aria-label="created"
      label={created}
      placement="top"
    >
      <Box maxWidth="fit-content">
        <Stack
          fontSize="sm"
          fontWeight={600}
          color={color}
          isInline
          alignItems="center"
          spacing={1}
        >
          <Box as={IoIosTimer} w={6} h={6} />
          <Stack isInline alignItems="center" spacing={1}>
            <Box>
              <Trans>Updated</Trans>
            </Box>
            <Box>
              <TimeAgo datetime={updated} locale={matchLocale(i18n.locale)} />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Tooltip>
  );
});
