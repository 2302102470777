import { Button } from "@chakra-ui/react";

import { useFormContext } from "react-hook-form";

type Props = {
  isLoading?: boolean;
  text: string;
};

export const SubmitButton: React.VFC<Props> = ({ isLoading, text }) => {
  const form = useFormContext();
  const { isValid } = form.formState;

  return (
    <Button
      data-cy="project-form__submit"
      type="submit"
      colorScheme="green"
      rounded="lg"
      isLoading={isLoading}
      isDisabled={!isValid}
    >
      {text}
    </Button>
  );
};
