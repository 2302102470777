import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { t, Trans } from "@lingui/macro";

import { FormControl, FormLabel } from "@chakra-ui/form-control";
import {
  Button,
  ButtonGroup,
  Flex,
  FormHelperText,
  Stack,
} from "@chakra-ui/react";

import { toggleMany } from "shared/lib/array";

import { Model } from "types";

import { ModelsPreset, pickModelId } from "entities/model";
import { useController, useFormContext } from "react-hook-form";
import Select from "react-select";

import { FormValues } from "../types";
import { GroupBox } from "./group-box";

type Props = {
  presets: ModelsPreset[];
  models: Model[];
  isEdit: boolean | undefined;
};

export const ModelSelectType: React.VFC<Props> = memo(
  ({ presets, models, isEdit }) => {
    const { control } = useFormContext<FormValues>();

    const { totalCount, allModels } = useMemo(() => {
      return {
        // flatGroup: toFlatModelsGroups(models),
        totalCount: models.length,
        allModels: models,
        // maxOptions: getMaxOptions(models),
      };
    }, [models]);

    const options = useMemo(() => {
      const values = presets.map(({ name }) => ({
        value: name,
        label: t({ id: name }),
      }));
      return values.concat([{ value: "custom", label: t`Custom` }]);
    }, [presets]);

    const modelsController = useController({
      name: "displayProperties.models",
      control,
      rules: {
        required: true,
      },
    });

    const [selectValue, setSelectValue] = useState(() => options[0]);

    const [activeGroup, setActiveGroup] = useState(() => allModels[0].name);

    // const currentCheckBoxes = useMemo(() => {
    //   return {
    //     id: uuidv4(),
    //     items: allModels.filter(({ name }) => name === activeGroup)[0].blocks,
    //   };
    // }, [allModels, activeGroup]);

    const selectedModels = modelsController.field.value as string[];

    useEffect(() => {
      if (selectedModels?.length === 0) {
        setSelectValue({ value: "", label: t`Select type` });
        return;
      }

      const maybePreset = presets.find(
        (preset) =>
          preset.models.every((modelId) => selectedModels?.includes(modelId)) &&
          preset.models.length === selectedModels?.length
      );

      if (maybePreset)
        setSelectValue({
          value: maybePreset.name,
          label: t({ id: maybePreset.name }),
        });
      else setSelectValue({ value: "custom", label: t`Custom` });
    }, [selectedModels]);

    // const handleChangeCheckBox = (id: string, isChecked: boolean) => {
    //   if (isChecked) {
    //     modelsController.field.onChange(
    //       selectedModels.filter((modelId) => modelId !== id)
    //     );
    //   } else modelsController.field.onChange([...selectedModels, id]);
    // };

    const handleClear = useCallback(
      () => modelsController.field.onChange([]),
      []
    );

    const handleSelectAll = useCallback(
      () => modelsController.field.onChange(allModels.map(pickModelId)),
      [allModels]
    );
    return (
      <Stack spacing={4} flex={1}>
        <FormControl isRequired>
          <FormLabel htmlFor="project-type">
            <Trans>Project type</Trans>
          </FormLabel>
          <Select
            id="project-type"
            onChange={(option) => {
              if (!option) return;
              setSelectValue(option);
              const group = presets.find(({ name }) => name === option.value);
              if (group) modelsController.field.onChange(group.models);
            }}
            placeholder={t`Select type`}
            components={{ IndicatorSeparator: () => null }}
            value={selectValue}
            options={options}
          />
        </FormControl>
        <FormControl>
          <FormLabel display="flex" alignItems="center" mb={0}>
            <Trans>Selected models</Trans>
            <FormHelperText mt={0} ml={2} as="span">
              {selectedModels?.length}/{totalCount}
            </FormHelperText>
          </FormLabel>
          <FormHelperText mt={0}>
            <Trans>These models will be added to your project</Trans>
          </FormHelperText>
        </FormControl>
        <Flex
          maxW={387}
          p={2}
          justifyContent="flex-start"
          style={{
            gap: "8px",
          }}
          border={"1px"}
          borderColor={"gray.200"}
          borderRadius={"lg"}
          flexWrap="wrap"
          display="flex"
          maxHeight={"400px"}
          overflow={"auto"}
        >
          {allModels.map((item) => {
            const { name } = item;
            const activeCount = selectedModels?.includes(item.id) ? 1 : 0;

            return (
              <GroupBox
                isActive={name === activeGroup}
                count={activeCount}
                name={name}
                key={name}
                onHover={() => setActiveGroup(name)}
                onClick={() => {
                  modelsController.field.onChange(
                    toggleMany(
                      selectedModels,
                      [item.id],
                      activeCount === 1 ? "remove" : "add"
                    )
                  );
                }}
              />
            );
          })}
        </Flex>

        {/* <Box minH={`${ONE_CHECKBOX_HEIGHT * maxOptions}rem`}>
        <AnimatePresence>
          <motion.div
            layout="position"
            initial="hidden"
            animate="visible"
            variants={list}
          >
            {currentCheckBoxes.items.map(({ name, displayName }) => {
              const isChecked = selectedModels.includes(name);
              return (
                <motion.div key={name} variants={item}>
                  <Checkbox
                    isChecked={isChecked}
                    onChange={() => handleChangeCheckBox(name, isChecked)}
                  >
                    <Trans id={displayName || name} />
                  </Checkbox>
                </motion.div>
              );
            })}
          </motion.div>
        </AnimatePresence>
      </Box> */}
        <ButtonGroup variant="outline" size="sm" alignSelf="flex-end">
          <Button onClick={handleClear}>
            <Trans>Clear</Trans>
          </Button>
          <Button onClick={handleSelectAll}>
            <Trans>Select all</Trans>
          </Button>
        </ButtonGroup>
      </Stack>
    );
  }
);

// const list = {
//   visible: {
//     opacity: 1,
//     transition: {
//       when: "beforeChildren",
//       staggerChildren: 0.05,
//     },
//   },
//   hidden: {
//     opacity: 0,
//     transition: {
//       when: "afterChildren",
//     },
//   },
// };

// const item = {
//   visible: { opacity: 1 },
//   hidden: { opacity: 0 },
// };
