import { IconProps } from "@chakra-ui/react";

import { Buildings } from "components/icons/buildings";
import { CityScape } from "components/icons/cityscape";
import { Crane } from "components/icons/crane";
import { Forest } from "components/icons/forest";
import { Road } from "components/icons/road";
import { SegmentAnything } from "components/icons/segment-anything";
import { Tractor } from "components/icons/tractor";
import { UavHouse } from "components/icons/uav-house";

export const ALL_UNION_NAMES = [
  "buildings",
  "city-scape",
  "crane",
  "forest",
  "road",
  "tractor",
  "uav-house",
  "segment-anything",
] as const;

type UnionNamesTuple = typeof ALL_UNION_NAMES;

export type UnionName = UnionNamesTuple[number];

export const isUnionIcon = (value: any): value is UnionName => {
  return ALL_UNION_NAMES.indexOf(value) !== -1;
};
//

export const IconConfig: Record<UnionName, React.VFC<IconProps>> = {
  buildings: Buildings,
  "city-scape": CityScape,
  crane: Crane,
  forest: Forest,
  road: Road,
  tractor: Tractor,
  "uav-house": UavHouse,
  "segment-anything": SegmentAnything,
};
