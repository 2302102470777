import { Trans } from "@lingui/macro";

import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";

import { FiPlus } from "@react-icons/all-files/fi/FiPlus";

import { ModalProjectCreate } from "./modals/project/create";

const ProjectCreateButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        data-cy="create-project-button"
        borderRadius="ui-base"
        leftIcon={<FiPlus />}
        onClick={onOpen}
        color="blue.500"
      >
        <Trans>Create project</Trans>
      </Button>
      <ModalProjectCreate onClose={onClose} isOpen={isOpen} />
    </>
  );
};

export default ProjectCreateButton;
