import { t } from "@lingui/macro";

import { ModelId } from "types";

export const i18nModelNames: Record<ModelId, any> = {
  [ModelId.DenseBuildings]: t`dense-buildings-model-title`,
  [ModelId.Buildings]: t`buildings-model-title`,
  [ModelId.Roads]: t`roads-model-title`,
  [ModelId.Forest]: t`forest-model-title`,
  [ModelId.Constructions]: t`constructions-model-title`,
  [ModelId.Fields]: t`fields-model-title`,
  [ModelId.UavBuildings]: t`uav-buildings-title`,
  [ModelId.SAM]: t`sam-model-title`,
};

export const i18nModelDescriptions: Record<ModelId, any> = {
  [ModelId.DenseBuildings]: t`dense-buildings-model-description`,
  [ModelId.Buildings]: t`buildings-model-description`,
  [ModelId.Roads]: t`roads-model-description`,
  [ModelId.Forest]: t`forest-model-description`,
  [ModelId.Constructions]: t`constructions-model-description`,
  [ModelId.Fields]: t`fields-model-description`,
  [ModelId.UavBuildings]: t`uav-buildings-description`,
  [ModelId.SAM]: t`sam-model-description`,
};
