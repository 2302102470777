import { memo } from "react";

import { t } from "@lingui/macro";

import { Box, ModalHeader } from "@chakra-ui/react";

import { UnionIcon } from "components/union-icon";
import { UnionName } from "components/union-icon/config";

import { AnimatePresence, motion } from "framer-motion";
import { useFormContext } from "react-hook-form";

import { FormValues } from "../types";

export const TitleModal = memo(() => {
  const { watch } = useFormContext<FormValues>();

  const name = watch("name");
  const color = watch("displayProperties.color");
  const iconName = watch("displayProperties.icon") as UnionName | undefined;

  const showPreview = color || iconName;

  return (
    <ModalHeader
      p={0}
      minH={10}
      color={name ? "black" : "gray.300"}
      display="flex"
      alignItems="center"
    >
      <AnimatePresence initial={false}>
        {showPreview && (
          <motion.div
            initial={{ width: 0, height: "2.5rem" }}
            animate={{ width: "3.25rem", height: "2.5rem" }}
            exit={{ width: 0, opacity: 0 }}
          >
            <Box
              boxSize="full"
              maxW="2.5rem"
              borderRadius="ui-base"
              background={color}
              display="flex"
              alignItems="center"
              justifyContent="center"
              boxShadow="lg"
            >
              {iconName && (
                <UnionIcon
                  boxSize={6}
                  name={iconName}
                  css={`
                    path {
                      fill: ${!color ? "#000" : "#fff"};
                    }
                  `}
                />
              )}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
      {name || t`Project name`}
    </ModalHeader>
  );
});
