import { useRouter } from "next/router";

import { SortBy, SortOrder } from "types";

export const useQueryParams = () => {
  const router = useRouter();

  const search = (router.query?.search || "") as string;
  const page = Number(router.query?.page) || 1;
  const pageSize = Number(router.query?.pageSize) || 10;
  const sortBy = (router.query?.sortBy as string) || SortBy.updated;
  const sortOrder = (router.query?.sortOrder as string) || SortOrder.desc;

  return { search, page, pageSize, sortBy, sortOrder };
};
