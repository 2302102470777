import React, { useRef, VFC } from "react";

import { Trans } from "@lingui/macro";

import { Button } from "@chakra-ui/button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/modal";

type Props = Omit<ModalProps, "children"> & {
  onConfirm: () => void;
};

const ModalProjectDelete: VFC<Props> = ({ onConfirm, ...modalProps }) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const confirmRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal
      initialFocusRef={cancelRef}
      finalFocusRef={confirmRef}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent borderRadius="ui-base">
        <ModalHeader>
          <Trans>Delete project?</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Trans>Are you sure you want to delete this project?</Trans>
        </ModalBody>
        <ModalFooter>
          <Button
            data-cy="modal-project-cancel-button"
            ref={cancelRef}
            onClick={modalProps.onClose}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            data-cy="modal-project-delete-button"
            ref={confirmRef}
            colorScheme="red"
            ml={3}
            onClick={onConfirm}
          >
            <Trans>Delete</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalProjectDelete;
