import { useMutation, useQueryClient } from "react-query";

import { t, Trans } from "@lingui/macro";

import { useDisclosure } from "@chakra-ui/hooks";
import { MenuItem } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";

import ModalProjectDelete from "components/modals/delete-project";

import { useIsAdmin } from "models/keycloak";

import { deleteProject } from "shared/api/client";

type Props = { projectId: string; isDemo: boolean };

export const DeleteProjectButton = ({ projectId, isDemo }: Props) => {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { mutate: onDelete, isLoading } = useMutation(deleteProject, {
    onSuccess: async () => {
      queryClient.invalidateQueries("projects");

      toast({
        title: t`Project deleted`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onError: (e) => {
      console.error(e);

      toast({
        title: t`Error delete project`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const handleShowDeleteAlert = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onOpen();
  };

  const handleDelete = () => {
    onDelete(projectId);
    onClose();
  };

  const isAdmin = useIsAdmin();

  const isHiddenForUserRole = isDemo && !isAdmin;

  return (
    <>
      {!isHiddenForUserRole && (
        <MenuItem
          aria-label="edit-project"
          textAlign={"center"}
          fontSize="0.875rem"
          m={0}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onOpen();
          }}
        >
          <Trans>Delete</Trans>
        </MenuItem>
      )}
      <ModalProjectDelete
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleDelete}
      />
    </>
  );
};
