import { useRouter } from "next/router";

import { defineMessage, t, Trans } from "@lingui/macro";

import { Button } from "@chakra-ui/button";
import { Box } from "@chakra-ui/layout";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/menu";

import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";

import { useUpdateQueryParams } from "shared/lib/hooks/use-update-query-params";

import { SortBy, SortOrder } from "types";

export const SortMenu = () => {
  const router = useRouter();

  const updateQueryParams = useUpdateQueryParams();

  const sortBy = (router.query?.sortBy as SortBy) || SortBy.updated;
  const sortOrder = (router.query?.sortOrder as SortOrder) || SortOrder.desc;

  return (
    <Box display={["none", "block"]}>
      <Menu closeOnSelect={false} autoSelect={false}>
        <MenuButton
          data-cy="projects-page__sort-by-button"
          borderRadius="ui-base"
          as={Button}
          rightIcon={<FiChevronDown />}
        >
          <Trans id={sortByTrans[sortBy]?.id} />
        </MenuButton>
        <MenuList borderRadius="ui-base">
          <MenuOptionGroup value={sortOrder} title={t`Order`} type="radio">
            {Object.values(SortOrder).map((value) => (
              <MenuItemOption
                data-cy={`projects-page__sort-by-order-${value}`}
                key={value}
                value={value}
                onClick={() => updateQueryParams({ sortOrder: value })}
              >
                <Trans id={sortOrderTrans[value]?.id} />
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          <MenuDivider />

          <MenuOptionGroup type="radio" value={sortBy} title={t`Sort By`}>
            {Object.values(SortBy).map((value) => (
              <MenuItemOption
                data-cy={`projects-page__sort-by-${value}`}
                key={value}
                value={value}
                onClick={() => updateQueryParams({ sortBy: value })}
              >
                <Trans id={sortByTrans[value]?.id} />
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};

const sortByTrans = {
  [SortBy.created]: defineMessage({
    id: "sort-projects-by-created",
    message: "created",
  }),
  [SortBy.updated]: defineMessage({
    id: "sort-projects-by-updated",
    message: "updated",
  }),
  [SortBy.name]: defineMessage({
    id: "sort-projects-by-name",
    message: "name",
  }),
};

const sortOrderTrans = {
  [SortOrder.asc]: defineMessage({
    id: "sort-projects-by-order-asc",
    message: "asc",
  }),
  [SortOrder.desc]: defineMessage({
    id: "sort-projects-by-order-desc",
    message: "desc",
  }),
};
