import { Box, BoxProps, Icon, IconProps } from "@chakra-ui/react";

import { UnionIcon } from "components/union-icon";

import { isUnionIcon } from "../union-icon/config";

type Props = {
  iconName?: string;
  bgColor?: string;
  boxProps?: BoxProps;
};

export const Badge: React.VFC<Props> = ({
  iconName = "",
  bgColor,
  boxProps,
}) => {
  return (
    <Box boxSize={"50px"} {...boxProps}>
      <Box boxSize={"50px"} position="relative">
        <Tringle
          boxSize={"50px"}
          right={0}
          position="absolute"
          color={bgColor || "transparent"}
        />
        {isUnionIcon(iconName) && (
          <UnionIcon
            position="absolute"
            zIndex={10}
            boxSize={5}
            right="0.55rem"
            top={2}
            left={2}
            name={iconName}
            css={`
              path {
                fill: ${bgColor ? "#fff" : "#000"};
              }
            `}
          />
        )}
      </Box>
    </Box>
  );
};

const Tringle = (props: IconProps) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      fill="none"
      style={{ transform: "rotate(-90deg)" }}
      {...props}
    >
      <path
        fill="currentColor"
        d="m25.09218,0.19946c3.6792,-0.00003 6.66175,3.42583 6.6618,7.65191l0.00018,19.86555c0.00004,3.4591 -3.51163,5.34965 -5.8261,3.13649l-24.50913,-23.43627c-2.55457,-2.44275 -1.05079,-7.21749 2.27314,-7.21752l21.40012,-0.00017z"
      />
    </Icon>
  );
};
