import { Trans } from "@lingui/macro";

import { Box, Flex, Text } from "@chakra-ui/react";

import { AiFillFolderOpen } from "@react-icons/all-files/ai/AiFillFolderOpen";

export const SearchNotFound = () => {
  return (
    <Flex h="100%" flexGrow={1} justifyContent="center" alignItems="center">
      <Flex
        p={6}
        alignItems="center"
        flexDirection="column"
        h="fit-content"
        borderRadius="ui-base"
      >
        <Box w="fit-content">
          <Box fill="gray.300" as={AiFillFolderOpen} boxSize={32} />
        </Box>
        <Text ml={4} as="h3" color="gray.400" fontSize="xl">
          <Trans>Sorry, no search results</Trans>
        </Text>
      </Flex>
    </Flex>
  );
};
