import { memo, useCallback } from "react";

import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
} from "@chakra-ui/react";

import { StickerAdd } from "components/icons/sticker-add";
import { UnionIcon } from "components/union-icon";
import { ALL_UNION_NAMES } from "components/union-icon/config";

import { useController, useFormContext } from "react-hook-form";

import { FormValues } from "../types";

export const IconPicker = memo(() => {
  const { control } = useFormContext<FormValues>();

  const controller = useController({ name: "displayProperties.icon", control });

  const handleClear = useCallback(() => {
    controller.field.onChange(undefined);
  }, []);

  return (
    <Popover isLazy>
      <PopoverTrigger>
        <Button size="xs" variant="ghost" py={2} px={1} _focus={{}}>
          <Box as={StickerAdd} boxSize={5} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        width="17rem"
        margin="unset"
        _focus={{
          boxShadow: "0 0 0 2px rgba(66, 153, 225, 0.6)",
        }}
      >
        <PopoverArrow />
        <PopoverBody p={4}>
          <SimpleGrid columns={6} spacing={2} justifyItems="center">
            <Button
              boxSize={8}
              minW={8}
              variant="outline"
              onClick={handleClear}
              overflow="hidden"
              p={0}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                <line
                  strokeWidth="1"
                  strokeLinecap="round"
                  stroke="#eb7070"
                  y2="32"
                  x2="0"
                  y1="0"
                  x1="32"
                  fill="none"
                />
              </svg>
            </Button>
            {ALL_UNION_NAMES.map((iconName) => (
              <Button
                key={iconName}
                boxSize={8}
                minW={8}
                p={2}
                variant="outline"
                onClick={() => controller.field.onChange(iconName)}
              >
                <UnionIcon name={iconName} boxSize={6} />
              </Button>
            ))}
          </SimpleGrid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});
