import { memo, useCallback } from "react";

import { t, Trans } from "@lingui/macro";

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

import { BiColorFill } from "@react-icons/all-files/bi/BiColorFill";
import { BiErrorCircle } from "@react-icons/all-files/bi/BiErrorCircle";
import { FaRandom } from "@react-icons/all-files/fa/FaRandom";

import { hexPattern, randomColor } from "shared/lib/color";

import { HexColorPicker } from "react-colorful";
import { useController, useFormContext } from "react-hook-form";

import { FormValues } from "../types";

export const ColorPicker = memo(() => {
  const { control } = useFormContext<FormValues>();

  const controller = useController({
    name: "displayProperties.color",
    control,
    defaultValue: undefined,
    rules: {
      pattern: {
        value: hexPattern,
        message: t`Invalid Hex Pattern`,
      },
    },
  });

  const handleClear = useCallback(() => {
    controller.field.onChange(undefined);
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const newValue = value.length ? formatColor(event.target.value) : value;
      controller.field.onChange(newValue);
    },
    []
  );

  const handlePickerChange = useCallback((newColor: string) => {
    // Если двигать color-bar и нет никакого цвета, приходит значения "#NaNNaNNaN",
    if (newColor === "#NaNNaNNaN") return;
    else controller.field.onChange(newColor);
  }, []);

  const handleRandom = useCallback(() => {
    controller.field.onChange(randomColor());
  }, []);

  const error = controller.fieldState.error;

  const color = (controller.field.value as string) || "";

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          size="xs"
          variant="ghost"
          py={2}
          px={1}
          _focus={{}}
          boxShadow={error ? "0 0 2px 1px rgba(225, 66, 66, 0.4)" : ""}
        >
          {error && (
            <Box
              boxSize={4}
              background="white"
              position="absolute"
              right="-0.5rem"
              top="-0.5rem"
            >
              <Box as={BiErrorCircle} boxSize={4} fill="red.300" />
            </Box>
          )}
          <Box as={BiColorFill} boxSize={5} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        width="17rem"
        _focus={{
          boxShadow: "0 0 0 2px rgba(66, 153, 225, 0.6)",
        }}
      >
        <PopoverArrow />
        <PopoverBody py={2} px={4}>
          <Flex mb={2}>
            <FormControl isInvalid={Boolean(error)}>
              <InputGroup display="flex" flexDirection="column">
                <Input
                  placeholder={t`Hex color`}
                  size="sm"
                  focusBorderColor={error ? "red.500" : "blue.500"}
                  maxLength={7}
                  borderRadius="md"
                  onChange={handleInputChange}
                  value={color}
                />
                <InputRightElement boxSize="fit-content" mt={1} mr={1}>
                  <IconButton
                    variant="outline"
                    background="#cbd5e05c"
                    _hover={{ background: "#cbd5e08f" }}
                    icon={<FaRandom />}
                    onClick={handleRandom}
                    aria-label="Generate name"
                    borderRadius="md"
                    boxSize={6}
                    _focus={{}}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <ButtonGroup ml={2}>
              <Button
                variant="outline"
                colorScheme="blue"
                size="sm"
                onClick={handleClear}
                mt="auto"
              >
                <Trans>Clear</Trans>
              </Button>
            </ButtonGroup>
          </Flex>
          <HexColorPicker
            color={color ?? undefined}
            style={{ width: "100%" }}
            onChange={handlePickerChange}
            className="custom-pointer"
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});

const formatColor = (color: string) => {
  if (!color.startsWith("#")) return "#" + color;
  return color;
};
