import { useMemo } from "react";
import Link from "next/link";

import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { IconButton } from "@chakra-ui/button";
import { useColorMode } from "@chakra-ui/color-mode";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Badge as Tag } from "@chakra-ui/react";

import { BsThreeDots } from "@react-icons/all-files/bs/BsThreeDots";
import { FaShareAlt } from "@react-icons/all-files/fa/FaShareAlt";

import ProjectShareButton from "components/project-card/share-button";

import { useIsAdmin } from "models/keycloak";

import { useUserInfo } from "shared/api/client/hook";
import { getDateLocale, matchLocale } from "shared/lib/i18n";

import { Project, Role } from "types";

import { format } from "date-fns";

import { Badge } from "./badge";
import { DateMark } from "./date-mark";
import { DeleteProjectButton } from "./delete-button";
import { ProjectEditButton } from "./edit-button";
import { ProjectInfoCounts } from "./processing-info";
import { ProjectAuthor } from "./project-author";
import ShareIcon from "./share-icon";
import { Card, IconContainer } from "./ui";

const bg = { light: "white", dark: "gray.700" };

const formatDate = "M/d/y, HH:mm:ss ";

export const ProjectCard: React.FC<Project> = (project) => {
  const isAdmin = useIsAdmin();
  const { data: userData } = useUserInfo();

  const { colorMode } = useColorMode();
  const { i18n } = useLingui();

  const {
    id,
    name,
    description,
    processingCount,
    failedProcessingCount,
    succeedProcessingCount,
    demoSource,
    demo,
    created,
    updated,
    migratedFromMapflow,
    shareProject,
  } = project;

  const isSharedProject = shareProject.users.find(
    (sharedUser) => userData?.email === sharedUser.email
  );
  const borderColor = isSharedProject
    ? "purple.500"
    : demo
    ? demoSource
      ? "yellow.500"
      : "blue.500"
    : bg[colorMode];

  const createdTooltipText = `${t`Created`} ${format(
    new Date(created),
    formatDate,
    { locale: getDateLocale(matchLocale(i18n.locale)) }
  )}`;

  const isHireMenuList =
    isSharedProject &&
    (isSharedProject.role === Role.contributor ||
      isSharedProject?.role === Role.readOnly);

  const cardButtons = useMemo(() => {
    if (isSharedProject && isSharedProject.role === Role.maintainer) {
      return (
        <>
          {/* <ProjectEditButton projectId={id} baseProject={project} /> */}
          <ProjectShareButton projectId={id} />
        </>
      );
    }

    return (
      <>
        <ProjectEditButton projectId={id} baseProject={project} />
        <ProjectShareButton projectId={id} />
        <DeleteProjectButton isDemo={!!demoSource} projectId={id} />
      </>
    );
  }, [isSharedProject]);

  return (
    <Link href={`/projects/${id}`} passHref>
      <Card
        as="a"
        data-cy={`project-card-${id}`}
        data-component="project-card"
        shadow="md"
        borderRadius="ui-base"
        pl={10}
        pr={28}
        pt={8}
        pb={6}
        bg={bg[colorMode]}
        border="1px solid"
        borderColor={borderColor}
        _hover={{
          transform: "scale(1.025)",
          shadow: "lg",
        }}
      >
        {isSharedProject && (
          <Box position="absolute" bottom={"30px"} right={"20px"}>
            <ShareIcon
              author={shareProject.owners[0].email}
              role={isSharedProject.role}
            />
          </Box>
        )}

        <Badge
          iconName={project.displayProperties?.icon}
          bgColor={project.displayProperties?.color}
          boxProps={{
            position: "absolute",
            left: 2,
            top: 2,
          }}
        />
        <Flex h="full" direction="column" justify="space-between">
          <Flex justifyContent="space-between" alignItems="center">
            <Box w="full">
              <Heading maxW="200px" as="h2" fontSize="xl" mt={1} noOfLines={1}>
                {name}
              </Heading>
              {/* <Editable
                loading={isLoading}
                height="32px"
                value={name}
                onSubmit={handleRename}
                controlsProps={{ as: IconContainer }}
                inputProps={{ isRequired: true, maxLength: 30 }}
                previewProps={{
                  as: "h2",
                  fontSize: "xl",
                  fontWeight: "800",
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              /> */}
              <Text
                mt={2}
                fontSize="sm"
                color="gray.500"
                w="full"
                overflow="hidden"
                textOverflow="ellipsis"
                noOfLines={2}
              >
                {description}
              </Text>
            </Box>
            {!isHireMenuList && (
              <Box
                position="absolute"
                as={IconContainer}
                top={8}
                right={3}
                onClick={(e) => e.preventDefault()}
              >
                <Menu placement="left-start">
                  <MenuButton
                    as={IconButton}
                    color="black"
                    variant="ghost"
                    size="sm"
                    icon={<BsThreeDots />}
                    fontSize={"24px"}
                    p={"2px"}
                    aria-label="more..."
                  />
                  <MenuList
                    zIndex={100}
                    maxW="120px" // Set max width for MenuList
                    minW="120px"
                    p={0}
                  >
                    {cardButtons}
                  </MenuList>
                </Menu>
              </Box>
            )}
          </Flex>
          <Stack mt={2} spacing={2}>
            <Flex>
              {migratedFromMapflow && <Tag mr={"10px"}>WEB</Tag>}
              {isAdmin && (
                <ProjectAuthor author={shareProject?.owners[0].email} />
              )}
            </Flex>

            <ProjectInfoCounts
              processingCount={processingCount}
              succeedProcessingCount={succeedProcessingCount}
              failedProcessingCount={failedProcessingCount}
            />
            <DateMark created={createdTooltipText} updated={updated} />
          </Stack>
        </Flex>
      </Card>
    </Link>
  );
};
