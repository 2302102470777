export function matchLocale(locale: string) {
  switch (locale) {
    case "ru":
      return "ru";
    case "zh-CN":
      return "zh-CN";
    default:
      return "en";
  }
}
