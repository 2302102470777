import { GetServerSideProps, NextPage } from "next";

import { Trans } from "@lingui/macro";

import { Box, Flex, Heading, Stack } from "@chakra-ui/react";

import Layout from "components/layout";
import ProjectCreateButton from "components/project-create-button";

import { withPageAuthRequired } from "models/keycloak/with-page-auth-required";

import { ProjectList, SortingMenu } from "widgets/project-list";

const ProjectsPage: NextPage = () => {
  return (
    <Layout loading={false}>
      <Flex
        height="100%"
        minHeight="100%"
        direction="column"
        p={6}
        pt={8}
        pb={16}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Stack isInline alignItems="center" spacing="8">
            <Heading data-cy="projects-page__title">
              <Trans>Projects</Trans>
            </Heading>
            <ProjectCreateButton />
          </Stack>
          <SortingMenu />
        </Flex>
        <Flex flex="1" mt="8" overproject="hidden">
          <Box flex="1" height="100%">
            <ProjectList />
          </Box>
        </Flex>
      </Flex>
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  return {
    props: {},
  };
};

export default withPageAuthRequired(ProjectsPage);
