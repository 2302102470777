import { memo } from "react";

import { t, Trans } from "@lingui/macro";

import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { FormErrorMessage, Input } from "@chakra-ui/react";

import { useFormContext } from "react-hook-form";

import { config } from "../config";
import { FormValues } from "../types";

export const InputName = memo(() => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormValues>();

  const error = errors.name;

  return (
    <FormControl isRequired isInvalid={Boolean(error && error.message)}>
      <FormLabel htmlFor="name">
        <Trans>Name</Trans>
      </FormLabel>
      <Input
        id="name"
        placeholder={t`Project name`}
        {...register("name", {
          required: t`Please enter the project name`,
          maxLength: {
            value: config.name.maxLength,
            message: t`Max allowed length is ${config.name.maxLength} chars`,
          },
          minLength: {
            value: config.name.minLength,
            message: t`Min allowed length is ${config.name.minLength} chars`,
          },
        })}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
});
