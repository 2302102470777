import { DisplayModelType, Model } from "types";

export const getAddedModels = (
  requestedModels: DisplayModelType,
  responseModels: Model[]
) => {
  return Array.isArray(requestedModels) && Array.isArray(responseModels)
    ? requestedModels.filter((m) => !responseModels.find((r) => r.id === m))
    : [];
};

export const getRemovedModels = (
  requestedModels: DisplayModelType,
  responseModels: Model[]
) => {
  return Array.isArray(requestedModels) && Array.isArray(responseModels)
    ? responseModels
        .filter((m) => !requestedModels.find((r) => r === m.id))
        .map((r) => r.id)
    : [];
};
