import { IconProps } from "@chakra-ui/react";

import { IconConfig, UnionName } from "./config";

type Props = IconProps & {
  name: UnionName;
};

export const UnionIcon: React.VFC<Props> = ({ name, ...props }) => {
  const Component = IconConfig[name];

  return <Component {...props} />;
};
