import styled from "@emotion/styled";

import { Box } from "@chakra-ui/layout";

export const Card = styled(Box)`
  position: relative;
  max-width: 25rem;
  cursor: pointer;
  transition: all 400ms ease;
  height: 13rem;
  min-height: 13rem;
`;

export const IconContainer = styled.div`
  transition: all 250ms ease;

  opacity: 0;

  ${Card}:hover & {
    opacity: 1;
  }
`;
