import { t } from "@lingui/macro";

import { Box, Icon, Tooltip } from "@chakra-ui/react";

import { FaShareAlt } from "@react-icons/all-files/fa/FaShareAlt";

type Props = {
  author: string;
  role: string;
};

const ShareIcon: React.FC<Props> = ({ author, role }) => {
  return (
    <Tooltip
      label={t`Shared by ${author} (${role})`}
      hasArrow
      rounded="md"
      zIndex={"tooltip"}
      placement="right"
      width={"fit-content"}
    >
      <Box width={"fit-content"}>
        <Icon as={FaShareAlt} color="purple.500" w={"20px"} h={"22px"} />
      </Box>
    </Tooltip>
  );
};

export default ShareIcon;
