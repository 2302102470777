import { useEffect } from "react";

import { Box, BoxProps, Button, IconProps, Text } from "@chakra-ui/react";

import { useHover } from "shared/lib/hooks/use-hover";

type Props = BoxProps & {
  isActive?: boolean;
  count?: number;
  // icon: React.FC<IconProps>;
  name: string;
  onHover?: () => void;
};

export const GroupBox: React.VFC<Props> = ({
  count = 0,
  isActive,
  onHover,
  name,
  ...boxProps
}) => {
  const [ref, isHover] = useHover<HTMLDivElement>();

  useEffect(() => {
    if (isHover && onHover) onHover();
  }, [onHover, isHover]);

  return (
    <Box
      as={Button}
      ref={ref}
      variant="ghost"
      borderRadius="ui-base"
      boxSize={14}
      position="relative"
      border="2px solid transparent"
      borderColor={isActive ? "blue.200" : "transparent"}
      boxShadow="0px 0px 3px rgba(0, 0, 0, 0.25);"
      display="flex"
      alignItems="center"
      justifyContent="center"
      _hover={{ background: "blackAlpha.50" }}
      _focus={{
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
      }}
      minW="max-content"
      {...boxProps}
      zIndex={0}
      margin={0}
    >
      <Box width="100%" opacity={isActive ? 1 : 0.7}>
        {name}
      </Box>
      {count > 0 && (
        <Box
          position="absolute"
          bg="blue.400"
          borderRadius="full"
          boxSize={5}
          right={-2}
          top={-2}
          textAlign="center"
        >
          <Text color="white" fontSize="0.75rem" lineHeight={5}>
            {count}
          </Text>
        </Box>
      )}
    </Box>
  );
};
