import { memo } from "react";

import { t, Trans } from "@lingui/macro";

import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { FormErrorMessage, Textarea } from "@chakra-ui/react";

import { useFormContext } from "react-hook-form";

import { config } from "../config";
import { FormValues } from "../types";

export const InputDescription = memo(() => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormValues>();

  const error = errors.description;

  return (
    <FormControl isInvalid={Boolean(error && error.message)}>
      <FormLabel htmlFor="description">
        <Trans>Description</Trans>
      </FormLabel>
      <Textarea
        id="description"
        resize="none"
        rows={5}
        {...register("description", {
          maxLength: {
            value: config.description.maxLength,
            message: t`Max allowed length is ${config.description.maxLength} chars`,
          },
        })}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
});
