import React, { useCallback, useMemo, VFC } from "react";
import { useRouter } from "next/router";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { t } from "@lingui/macro";

import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/modal";
import { useToast } from "@chakra-ui/react";

import {
  createProject,
  fetchUserStatus,
  linkWorkflowDef,
} from "shared/api/client";

import { BaseProject, TeamStatus } from "types";

import { FormCreateEditProject } from "../create-edit-form";
import { getAddedModels } from "../utils";

type Props = Omit<ModalProps, "children">;

export const ModalProjectCreate: VFC<Props> = (modalProps) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const router = useRouter();

  const {
    data: userStatusData,
    isLoading: isUserStatusLoading,
    isSuccess: isUserStatusSuccess,
  } = useQuery<TeamStatus>({
    queryKey: ["teamStatus"],
    queryFn: fetchUserStatus,
  });

  const { isLoading, mutate } = useMutation(createProject, {
    onSuccess: async (res, payload) => {
      const responseModels = res.data.workflowDefs || [];
      const requestedModels = payload.displayProperties?.models || [];

      const workflowIds =
        payload.displayProperties?.models === "all" ||
        payload.displayProperties === null
          ? getAddedModels(
              userStatusData?.models.map((u) => u.id) || [],
              responseModels
            )
          : getAddedModels(requestedModels, responseModels);

      if (workflowIds.length > 0) {
        await addWorkflowsMutation.mutateAsync({
          projectId: res.data.id,
          workflowDefIds: workflowIds,
        });
      }
      router.push(`/projects/${res.data.id}`);
      queryClient.invalidateQueries("projects");
      modalProps.onClose();
    },
    onError: (error) => {
      toast({
        title: "Error create project",
        description: t`Error create project`,
        position: "bottom",
        status: "error",
        isClosable: true,
      });
      console.error(error);
    },
  });

  const addWorkflowsMutation = useMutation(linkWorkflowDef, {
    onError: (err) => {
      console.error(err);
    },
  });

  const handleSubmit = useCallback(
    (project: BaseProject) => {
      mutate(project);
    },
    [mutate]
  );

  const { models } = useMemo(() => {
    if (isUserStatusLoading || !isUserStatusSuccess || !userStatusData) {
      return {
        models: [],
      };
    }

    return {
      models: userStatusData.models.sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    };
  }, [userStatusData]);

  return (
    <Modal isCentered size="4xl" {...modalProps}>
      <ModalOverlay />
      <ModalContent borderRadius="ui-base">
        <FormCreateEditProject
          onSubmit={handleSubmit}
          isLoading={isLoading}
          models={models}
          isUserStatusLoading={isUserStatusLoading}
          isUserStatusSuccess={isUserStatusSuccess}
          isWorkflowLinkUnlinkLoading={addWorkflowsMutation.isLoading}
        />
      </ModalContent>
    </Modal>
  );
};
