import { Trans } from "@lingui/macro";

import { useDisclosure } from "@chakra-ui/hooks";
import { MenuItem } from "@chakra-ui/react";

import { ModalProjectShare } from "components/modals/project/share";

type Props = {
  projectId: string;
};

const ProjectShareButton: React.FC<Props> = ({ projectId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem
        aria-label="edit-project"
        textAlign={"center"}
        fontSize="0.875rem"
        m={0}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onOpen();
        }}
      >
        <Trans>Share</Trans>
      </MenuItem>
      <ModalProjectShare
        projectId={projectId}
        onClose={onClose}
        isOpen={isOpen}
      />
    </>
  );
};

export default ProjectShareButton;
