import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { Flex, SimpleGrid } from "@chakra-ui/react";

import ProjectCard from "components/project-card";

import { fetchProjects } from "shared/api/client";
import { useQueryParams } from "shared/lib/hooks/use-query-params";
import { SearchNotFound } from "shared/ui/search-not-found";

import { IBaseProject, SortBy, SortOrder } from "types";

import {
  EmptyProjectsView,
  ErrorView,
  FetchingLayoutView,
  PageWithoutData,
  Pagination,
} from "./ui";

export const ProjectList: React.VFC = () => {
  const { search, page, pageSize, sortBy, sortOrder } = useQueryParams();

  const { data, error, isFetching } = useQuery<IBaseProject, AxiosError>(
    ["projects", page, pageSize, sortBy, sortOrder, search],
    () =>
      fetchProjects({
        filter: search,
        offset: (page - 1) * pageSize,
        limit: pageSize,
        sortBy,
        sortOrder,
      }),
    {
      refetchInterval: 10 * 1000,
      keepPreviousData: true,
    }
  );

  const isNotData = typeof data === "undefined";

  if (error) return <ErrorView httpStatus={error?.response?.status} />;
  if (isNotData) return <FetchingLayoutView />;

  if (data && data?.results.length === 0) {
    if (search) return <SearchNotFound />;
    else if (page === 1) return <EmptyProjectsView />;
    return <PageWithoutData />;
  }

  return (
    <Flex direction="column" height="100%" mb="2rem">
      <Flex>
        <Pagination
          isLoading={isFetching}
          limit={pageSize}
          offset={(page - 1) * pageSize}
          total={data.total}
        />
      </Flex>
      <SimpleGrid spacing={5} minChildWidth="24rem" p={2}>
        {data?.results.map((project) => (
          <ProjectCard key={project.id} {...project} />
        ))}
      </SimpleGrid>
    </Flex>
  );
};
