import { Trans } from "@lingui/macro";

import { useDisclosure } from "@chakra-ui/hooks";
import { MenuItem } from "@chakra-ui/react";

import { ModalProjectEdit } from "components/modals/project/edit";

import { Project } from "types";

type Props = {
  projectId: string;
  baseProject: Project;
};

export const ProjectEditButton: React.VFC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem
        aria-label="edit-project"
        textAlign={"center"}
        fontSize="0.875rem"
        m={0}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onOpen();
        }}
      >
        <Trans>Edit</Trans>
      </MenuItem>
      <ModalProjectEdit onClose={onClose} isOpen={isOpen} {...props} />
    </>
  );
};
