import { matchLocale } from "shared/lib/i18n";

import { Locale } from "date-fns";
import { enUS, ru, zhCN } from "date-fns/locale";

type TKeys = ReturnType<typeof matchLocale>;

const FNSDict: Record<TKeys, Locale> = {
  ru: ru,
  en: enUS,
  "zh-CN": zhCN,
};

export const getDateLocale = (locale: TKeys) => {
  return FNSDict[locale];
};
