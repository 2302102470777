import { t, Trans } from "@lingui/macro";

import { Box, Stack, Text } from "@chakra-ui/react";

import { BiCheckCircle } from "@react-icons/all-files/bi/BiCheckCircle";
import { GiBrain } from "@react-icons/all-files/gi/GiBrain";
import { RiErrorWarningLine } from "@react-icons/all-files/ri/RiErrorWarningLine";

import { Project } from "types";

import { CountTag } from "./count-tag";

type Props = Pick<
  Project,
  "processingCount" | "succeedProcessingCount" | "failedProcessingCount"
>;

export const ProjectInfoCounts: React.FC<Props> = ({
  processingCount,
  succeedProcessingCount,
  failedProcessingCount,
}) => {
  const inProgressProcessingsCount =
    processingCount - succeedProcessingCount - failedProcessingCount;

  return (
    <Stack isInline fontSize="xs" fontWeight={600} color="gray.900" spacing={1}>
      {processingCount === 0 && (
        <Box py={1} px={1} color="gray.500">
          <Text>
            <Trans>No processings</Trans>
          </Text>
        </Box>
      )}
      <CountTag
        color="blue.500"
        icon={GiBrain}
        count={inProgressProcessingsCount}
        tooltipMessage={t`In progress`}
        hideZero
      />
      <CountTag
        color="green.500"
        icon={BiCheckCircle}
        count={succeedProcessingCount}
        tooltipMessage={t`Succeed`}
        hideZero
      />
      <CountTag
        color="red.500"
        icon={RiErrorWarningLine}
        count={failedProcessingCount}
        tooltipMessage={t`Failed`}
        hideZero
      />
    </Stack>
  );
};
