import { memo } from "react";

import { Button, ButtonGroup, Flex, HStack } from "@chakra-ui/react";

import {
  LEFT_DOT,
  RIGHT_DOT,
  usePagination,
} from "shared/lib/hooks/use-pagination";
import { useUpdateQueryParams } from "shared/lib/hooks/use-update-query-params";

import { PaginatedData } from "types";

interface TPagination extends Omit<PaginatedData, "records"> {
  isLoading: boolean;
}

const pageSizeVariants = [10, 25, 50] as const;

const ProjectsPagination: React.VFC<TPagination> = ({
  isLoading,
  limit,
  offset,
  total,
}) => {
  const updateQueryParams = useUpdateQueryParams();

  const pageSize = limit;
  const currentPage = 1 + Math.floor(offset / limit);
  const totalPages = Math.round(total / limit);

  const paginationOptions = {
    currentPage,
    pageSize: limit,
    totalCount: total,
    siblingCount: 1,
  };

  const paginationRange = usePagination(paginationOptions);

  const hasRange = (paginationRange?.length || 0) > 1;

  return (
    <Flex justifyContent="space-between" flexGrow={1} mb={3}>
      <HStack alignItems="center" px={6}>
        {hasRange &&
          paginationRange &&
          paginationRange.map((page) => {
            if (page === currentPage) {
              return (
                <Button key={page} variant="solid" disabled>
                  {page}
                </Button>
              );
            }

            let nextPage = page;

            if (page === LEFT_DOT) nextPage = Math.max(1, currentPage - 5);
            if (page === RIGHT_DOT)
              nextPage = Math.min(totalPages, currentPage + 5);

            return (
              <Button
                key={page}
                variant="outline"
                onClick={() => updateQueryParams({ page: nextPage })}
              >
                {page < 0 ? "..." : page}
              </Button>
            );
          })}
      </HStack>
      <Flex>
        <Button
          isDisabled
          display={isLoading ? "inline-flex" : "none"}
          isLoading={isLoading}
          mr={2}
        />
        <ButtonGroup isAttached alignItems="center">
          {pageSizeVariants.map((sizeVariant) => {
            if (sizeVariant === pageSize) {
              return (
                <Button key={sizeVariant} variant="outline" disabled>
                  {sizeVariant}
                </Button>
              );
            }

            return (
              <Button
                key={sizeVariant}
                variant="outline"
                onClick={() =>
                  updateQueryParams({ pageSize: sizeVariant, page: 1 })
                }
              >
                {sizeVariant}
              </Button>
            );
          })}
        </ButtonGroup>
      </Flex>
    </Flex>
  );
};

export const Pagination = memo(ProjectsPagination);
